import React from "react"
import Layout from "../../components/Layout"
import { css } from "@emotion/core"

import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table'
import './responsive-table.css'

import JSONData from "./sitelist.json"

export default function siteList() {
  return (
    <>
      <Layout>
          <h1 css={css`
            margin-bottom:1em;
          `}>Websites I've Worked On</h1>
          <p>A full list of websites I have been involved in the building or maintaining over the past couple of years.</p>
          <p>Some websites were built directly for the client, some where built while consulting through other agencies.</p>
          <Table>
            <Thead>
              <Tr>
                {JSONData.columns.map(column => (
                  <Th>{column.heading}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {JSONData.sites.map(site => (
                <Tr>
                  <Td>{site.title}</Td>
                  <Td><a href={`https://`+site.url}>{site.url}</a></Td>
                  <Td>{site.agency}</Td>
                  <Td>{site.designer}</Td>
                  <Td>{site.date}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Layout>
    </>
  )
}
